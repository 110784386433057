import type { ReactNode } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import type { AppState } from 'types/app';
import useMartyContext from 'hooks/useMartyContext';
import { getAbsoluteMarketplaceUrl } from 'helpers/MarketplaceUtils';
import { merchantIdHostMapping } from 'constants/crossSiteMerchantIdMapping';

interface Props {
  children?: ReactNode;
  className?: string;
  target?: string;
  to: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => any;
  [key: string]: any;
}

export const MarketplaceAwareLink = (props: Props) => {
  const {
    marketplace: { merchantId: marketplaceMerchantId }
  } = useMartyContext();
  const { onClick, to, merchantId, ...rest } = props;
  const { host } = useSelector((state: AppState) => state.url);

  // if item level merchantId does not match marketplace merchantId, then set destination to passed in merchantId
  const marketplaceDestination = merchantId !== marketplaceMerchantId && merchantIdHostMapping[merchantId];

  // item level merchantId and marketplace merchantId match, use <Link /> and relative URL
  if (!marketplaceDestination) {
    return <Link to={to ?? ''} onClick={onClick} {...rest} />;
  }

  // get the appropriate environment URL based on what merchantId mismatched
  const marketplaceUrl = marketplaceDestination ? getAbsoluteMarketplaceUrl(marketplaceDestination, host) : '';

  /* content comes in the form of the `props.children` property */
  /* eslint-disable-next-line jsx-a11y/anchor-has-content */
  return <a href={`${marketplaceUrl}${to}`} onClick={onClick} {...rest} />;
};

export default MarketplaceAwareLink;
