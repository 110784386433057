/* eslint css-modules/no-unused-class: [2, { markAsUsed: ['image', 'imageNoBackground', 'fullWidth'] }] */
import React from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import type { AppState } from 'types/app';
import useMartyContext from 'hooks/useMartyContext';
import { FILE_NAME_NO_EXT } from 'common/regex';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import getCardData from 'data/getCardData';
import CardMedia from 'components/common/card/CardMedia';
import MarketplaceAwareLink from 'components/common/MarketplaceAwareLink';
import { fetchHeartList, heartProduct, unHeartProduct } from 'actions/hearts';
import { HEART_DEFAULT_LIST_ID } from 'constants/apis';
import Hearts from 'components/common/Hearts';

import css from 'styles/components/cart/productImage.scss';
import melodyCardCSS from 'styles/components/common/melodyCard.scss';

interface Props {
  fetchHeartList: any;
  heartProduct: any;
  unHeartProduct: any;
  heartsStyleIds: string[];
  isCustomer: boolean;
  item: any; // TODO ts use correct type when cloudlist is typed
  className?: string;
  dataTe?: string;
  tabIndex?: number;
  ariaHidden?: boolean;
  merchantId: string;
  isModal?: boolean;
  showFixedQuantity?: boolean;
  children?: React.ReactNode;
}

interface Map {
  [key: string]: number;
}

export const ProductImage = ({
  heartProduct,
  unHeartProduct,
  fetchHeartList,
  heartsStyleIds,
  isCustomer,
  item,
  className,
  dataTe,
  tabIndex = 0,
  ariaHidden = false,
  merchantId,
  showFixedQuantity,
  isModal,
  children,
  ...rest
}: Props) => {
  const {
    imageId,
    imageUrl,
    image: { defaultUrl = undefined } = {},
    egc,
    asin,
    productId,
    brandName,
    brand,
    style,
    productName,
    recoName,
    originalPrice,
    price,
    styleId
  } = item;

  const {
    testId,
    marketplace: {
      shortName,
      pdp: { egcUrl }
    }
  } = useMartyContext();

  let link: string = '';
  let alt: string = '';
  const parsedImageId = imageId ? imageId : (imageUrl || defaultUrl).match(FILE_NAME_NO_EXT)[0];

  if (egc) {
    link = egcUrl;
    alt = `${shortName} Gift Card`;
  } else {
    link = asin ? `/p/asin/${asin}` : `/product/${productId}`;
    alt = `${brandName || brand} ${productName || style}`;
  }

  link = recoName ? `${link}?ref=${recoName}` : link;

  const { media } = getCardData({
    imageId: parsedImageId,
    originalPrice,
    price,
    productId,
    styleId,
    alt
  });

  const onToggleHeart = () => {
    const isHearted = heartsStyleIds.includes(styleId);

    // do not fetch heart list in cart modal heart/unheart https://github01.zappos.net/mweb/marty/issues/16471
    if (isHearted) {
      unHeartProduct(
        {
          itemId: styleId,
          subItemId: asin,
          listId: HEART_DEFAULT_LIST_ID,
          merchantId
        },
        !isModal && fetchHeartList
      );
    } else {
      heartProduct({ subItemId: asin, listId: HEART_DEFAULT_LIST_ID, merchantId }, fetchHeartList);
    }
  };

  const heartsList: Map = {};
  heartsList[styleId] = 0;
  const showFavoriteHeart = isCustomer && !egc && !showFixedQuantity;
  const heartInfo = {
    showFavoriteHeart,
    isDisplayCount: false,
    onHeartClick: onToggleHeart,
    styleId: styleId,
    productId: productId
  };

  return (
    <div className={cn(css.container, melodyCardCSS.mCard, className)} {...rest}>
      <MarketplaceAwareLink
        merchantId={merchantId}
        data-test-id={testId('cartItemImage')}
        tabIndex={tabIndex}
        aria-hidden={ariaHidden}
        to={link}
        data-te={dataTe || 'TE_CART_PRODUCTCLICKED'}
        data-ted={asin}
      >
        {children}
        <CardMedia {...media}>{showFavoriteHeart && <Hearts {...heartInfo} />}</CardMedia>
      </MarketplaceAwareLink>
    </div>
  );
};

export function mapStateToProps(state: AppState) {
  const { cookies, hearts } = state;
  const { heartsStyleIds } = hearts;
  const isCustomer = !!cookies['x-main'];
  return { hearts, heartsStyleIds, isCustomer };
}

const connector = connect(mapStateToProps, {
  heartProduct,
  unHeartProduct,
  fetchHeartList
});
const ConnectedProductImage = connector(ProductImage);
export default withErrorBoundary('ProductImage', ConnectedProductImage);
