import React from 'react';
import { Link } from 'react-router-dom';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import MarketplaceAwareLink from 'components/common/MarketplaceAwareLink';
import { IS_GIFT_CARD } from 'common/regex';
import { inIframe } from 'helpers/InIframe';

import css from 'styles/components/cart/itemDescription.scss';

interface Props {
  item: any; // TODO ts use correct type when cloudlist stuff is typed
  isModal: boolean;
  isRecommendedFit: boolean;
  merchantId: string;
}

export const ItemDescription = ({ item, isModal, isRecommendedFit, merchantId }: Props) => {
  const { sizing, egc, asin, color, brandName, stockId } = item;

  const { testId, marketplace } = useMartyContext();
  const isCLT = inIframe();

  const makeProductName = () => {
    const { productName, brandName } = item;
    return productName.replace(brandName, '');
  };

  const makeSizingFields = () => {
    let { size, width, inseam } = item;

    if (!sizing && !size) {
      return null;
    }

    if (sizing) {
      const { displaySize, shoeWidth, inseam: productInseam } = sizing.languageTagged;
      (size = displaySize), (width = shoeWidth), (inseam = productInseam);
    }

    return (
      <>
        {!!size && (
          <>
            {isRecommendedFit ? (
              <li className={css.recommendedFit}>
                Recommended Size: <span data-test-id={testId('itemSize')}>{size}</span>
              </li>
            ) : (
              <li>
                Size: <span data-test-id={testId('itemSize')}>{size}</span>
              </li>
            )}
          </>
        )}
        {!!width && (
          <li>
            Width: <span data-test-id={testId('itemWidth')}>{width}</span>
          </li>
        )}
        {!!inseam && (
          <li>
            Inseam: <span data-test-id={testId('itemInseam')}>{inseam}</span>
          </li>
        )}
      </>
    );
  };

  const {
    pdp: { egcUrl },
    checkout: { asinVerbiage }
  } = marketplace;

  if (egc) {
    const { recipientEmail, deliveryDate } = item;
    return (
      <div className={cn(css.container, css.dimensions, { [css.modal]: isModal })}>
        <Link to={egcUrl} data-test-id={testId('itemName')}>
          <span className={css.productName} data-test-id={testId('itemName')}>
            {makeProductName()}
          </span>
        </Link>
        <ul>
          <li>
            Color: <span data-test-id={testId('itemColor')}>{color}</span>
          </li>
          <li>
            Email To:{' '}
            <span className={css.recipientEmail} data-test-id={testId('recipientEmail')}>
              {recipientEmail}
            </span>
          </li>
          <li>
            Deliver On: <span data-test-id={testId('deliveryDate')}>{deliveryDate}</span>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className={cn(css.container, css.dimensions, { [css.modal]: isModal })} data-test-id={testId('itemDescription')}>
      <MarketplaceAwareLink merchantId={merchantId} to={`/p/asin/${asin}`} data-te="TE_CART_PRODUCTCLICKED" data-ted={asin}>
        <span className={css.brandName} data-test-id={testId('brandName')}>
          {brandName}
        </span>
        <span className={css.productName} data-test-id={testId('itemName')}>
          {makeProductName()}
        </span>
      </MarketplaceAwareLink>
      <ul>
        {!IS_GIFT_CARD.test(brandName) && ( // dont render color for physical gift cards
          <li>
            Color: <span data-test-id={testId('itemColor')}>{color}</span>
          </li>
        )}

        {makeSizingFields()}

        {isCLT && (
          <>
            <li>
              {asinVerbiage}: <span data-test-id={testId('itemAsin')}>{asin}</span>
            </li>
            {stockId && <li className={css.hiddenField}>StockId: {stockId}</li>}
          </>
        )}
      </ul>
    </div>
  );
};

const WithErrorBoundaryItemDescription = withErrorBoundary('ItemDescription', ItemDescription);
export default WithErrorBoundaryItemDescription;
