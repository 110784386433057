import React, { useState } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import type { CartItem } from 'types/mafia';
import useMartyContext from 'hooks/useMartyContext';
import { onModifyQuantity, onRemoveFromCart } from 'store/ducks/cart/actions';
import { cartError, changeQuantity } from 'actions/cart';
import { translateCartError } from 'apis/mafia';
import JanusPixel from 'components/common/JanusPixel';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';

import css from 'styles/components/cart/cartQuantityDropdown.scss';

interface OwnProps {
  item: CartItem;
  onChangeQuantityCb: (...args: any[]) => void;
  onChangeQuantityCbDone: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = OwnProps & PropsFromRedux;

const maxQuantity = 10;

export const makeOptions = (onHand: number, currentVal: number) => {
  /* if we have a ton on hand, just set to 10 */
  if (onHand > maxQuantity) {
    onHand = maxQuantity;
  }

  const options = [];

  for (let i = 1; i <= onHand; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  if (currentVal > onHand) {
    options.push(
      <option disabled key={currentVal} value={currentVal}>
        {currentVal}
      </option>
    );
  }

  return options;
};

export const CartQuantityDropdown = ({
  item,
  changeQuantity,
  cartError,
  onChangeQuantityCb,
  onChangeQuantityCbDone,
  onRemoveFromCart,
  onModifyQuantity
}: Props) => {
  const [isDeleting, setDeleting] = useState(false);

  const {
    testId,
    marketplace: {
      cart: { allowAdjustQuantity }
    }
  } = useMartyContext();

  const { egc, asin, quantity, stock, stockId, styleId, onHand, cartItemId } = item;

  const onChangeQuantity = ({
    asin,
    cartItemId,
    event: { currentTarget }
  }: {
    asin: string;
    cartItemId: string;
    event: React.ChangeEvent<HTMLSelectElement>;
  }) => {
    const { value } = currentTarget;

    if (!asin) {
      return;
    }

    const quantity = +value;

    onChangeQuantityCb(asin);

    if (quantity === 0) {
      setDeleting(true);
      onRemoveFromCart(asin);
    } else {
      onModifyQuantity(quantity, asin);
    }

    interface ItemData {
      asin: string;
      quantity: number;
      itemType?: string;
      cartItemId?: string;
    }

    const itemData: ItemData = {
      cartItemId: cartItemId,
      asin,
      quantity
    };

    changeQuantity({ items: [itemData] }).then(response => {
      !!quantity && onChangeQuantityCbDone();

      const error = translateCartError(response);

      if (error) {
        cartError(error);
      }
    });
  };

  const makeJanusPixel = () => {
    const queryParams = {
      teen: styleId,
      child: stockId,
      widget: 'DeleteCart'
    };

    return <JanusPixel queryParams={queryParams} />;
  };

  // if item is an egc, item is out of stock, item does not allow quantity adjustments, do not render dropdown
  if (egc || !stock || !allowAdjustQuantity) {
    return null;
  }

  return (
    <div className={css.container}>
      <label htmlFor={`quantity-${asin}`} data-test-id={testId('quantityDropdownLabel')}>
        Quantity
      </label>
      <select
        id={`quantity-${asin}`}
        value={quantity}
        onChange={event => onChangeQuantity({ asin, cartItemId, event })}
        data-test-id={testId('quantityDropdown')}
        name="quantity"
      >
        <option value={0}>Remove</option>
        {makeOptions(onHand, quantity)}
      </select>
      {isDeleting && makeJanusPixel()}
    </div>
  );
};

const mapDispatchToProps = {
  cartError,
  changeQuantity,
  onModifyQuantity,
  onRemoveFromCart
};

const connector = connect(null, mapDispatchToProps);
const ConnectedCartQuantityDropdown = connector(CartQuantityDropdown);
export default withErrorBoundary('CartQuantityDropdown', ConnectedCartQuantityDropdown);
